export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

// Should be in sync with dev center page id
export const GROUPS_MEMBERS_AREA_PAGE_ID = 'groups_area';

export const GROUPS_PLATFORM_CONTROLLER_TYPE = 'groupsPlatform';

export const GROUP_DISCUSSION_ID = 'discussion';

// page Id from https://dev.wix.com/
export const GROUPS_PAGE_ID = 'groups';
export const GROUP_PAGE_ID = 'group';

export const ABOUT_GROUP_PAGE_ID = 'about_group';
export const GROUP_PAGES = [GROUP_PAGE_ID, GROUPS_PAGE_ID];

export const PAGE_TITLES = {
  [GROUP_PAGE_ID]: 'Group Page',
  [GROUPS_MEMBERS_AREA_PAGE_ID]: 'Groups',
  [GROUPS_PAGE_ID]: 'Groups',
};

export const SITE_MEMBERS_APP_ID = '14dbefd2-01b4-fb61-32a7-3abd44da4908';
export const NOTIFICATIONS_APP_ID = '14f25924-5664-31b2-9568-f9c5ed98c9b1';
export const SETTINGS_APP_ID = '14f25dc5-6af3-5420-9568-f9c5ed98c9b1';
export const NOTIFICATIONS_PAGE_ID = 'notifications_app';
export const SETTINGS_PAGE_ID = 'settings';
export const ABOUT_APP_ID = '14dbef06-cc42-5583-32a7-3abd44da4908';
export const ABOUT_PAGE_ID = 'about';
export const GROUP_MEMBERS_AREA_PAGE = {
  appDefinitionId: '',
  pageId: GROUPS_MEMBERS_AREA_PAGE_ID,
  method: 'addSection',
  social: true,
  showInMemberMenu: true,
  loginMenuTitle: 'Groups',
};

export const NOTIFICATIONS_PAGE = {
  numbers: {
    key: 'notificationsCount',
    default: 0,
  },
  appDefinitionId: NOTIFICATIONS_APP_ID,
  pageId: NOTIFICATIONS_PAGE_ID,
  method: 'addApplication',
  menuOrder: 4,
  social: false,
  urlOverride: 'notifications',
};

export const SETTINGS_PAGE = {
  appDefinitionId: SETTINGS_APP_ID,
  pageId: SETTINGS_PAGE_ID,
  method: 'addApplication',
  menuOrder: 4,
  social: false,
  urlOverride: 'settings',
};

export const ABOUT_PAGE = {
  showInMemberMenu: true,
  numbers: {},
  social: true,
  appDefinitionId: ABOUT_APP_ID,
  pageId: ABOUT_PAGE_ID,
  method: 'addApplication',
  loginMenuTitle: 'Profile',
  showInLoginMenu: true,
  menuOrder: 1,
  socialHome: true,
  urlOverride: 'profile',
};

export const SITE_MEMBERS_PAGE = {
  method: 'addApplicationToSite',
  appDefinitionId: SITE_MEMBERS_APP_ID,
};

export const EXPERIMENTS_SCOPE = 'specs.groups';
